import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, withPrefix } from "gatsby"

import Helmet from "react-helmet"

import Moment from "moment"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"

Moment.locale("en")

const BulletinDetailPage = ({ data }) => {
  let ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach((item) => {
    if ("children" in item) {
      ancestor = item
    }
  })

  return (
    <>
      <Layout
        isContent={true}
        subMenu={ancestor.children}
        button={ancestor.button}
        parent={"officials"}
        siteTitle={"Bulletins"}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/officials/">Officials</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/officials/bulletins">Bulletins</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="main-area news-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3>{data.wagtail.page.bulletintypeName}</h3>
                <p className="pt-10">
                  {Moment(data.wagtail.page.date).format("MMMM D, YYYY")}
                </p>
                <h1>{data.wagtail.page.title}</h1>
                <p className="p-intro">{data.wagtail.page.detail}</p>
                <div
                  className="c-title-content mb-80"
                  dangerouslySetInnerHTML={{
                    __html: data.wagtail.page.bodySafe,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        {data.wagtail.page.fullContent &&
          data.wagtail.page.fullContent.length > 0 && (
            <CTABlock data={data.wagtail.page.fullContent[0]} />
          )}
      </Layout>
      <Helmet>
        <script src={withPrefix("scripts/bullets.js")} type="text/javascript" />
      </Helmet>
    </>
  )
}

BulletinDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: Int) {
    wagtail {
      page(id: $id) {
        ... on BulletinPage {
          id
          date
          bulletintypeName
          title
          bodySafe
          detail
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
          ancestors {
            ... on OfficialsPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
                ... on PositionsPage {
                  live
                  isProtected
                }
                ... on OfficialPage {
                  live
                  isProtected
                }
                ... on BulletinsPage {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }
        }
      }
    }
  }
`

export default BulletinDetailPage
